/* Mixins */
.asset-link-device-component .link-device {
  padding: 8px;
  background-color: #eee;
  margin: 24px 0;
}
.asset-link-device-component .transform-column, .asset-link-device-component .transform-column-middle {
  height: 45vh !important;
}
.asset-link-device-component .left-transform-column .ul-list {
  max-height: calc(45vh - 48px) !important;
  height: calc(100% - 48px) !important;
}
.asset-link-device-component .tip-text {
  display: inline-block;
  color: #575757;
  margin-top: 8px;
}